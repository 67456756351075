<template>
	<div class="d-flex flex-column">
		<div v-if="!loading" class="d-flex align-center justify-space-between">
			<v-btn
				v-if="!technicalSupervisor"
				class="text-lowercase text-simple icon-grey"
				text
				tile
				depressed
				rounded
				:disabled="managerItr && status == 'new' ? false : disabled || disableExecutors"
				@click="showExecutorsModal = true"
			>
				<v-icon class="mr-4">
					mdi-account-multiple-outline
				</v-icon>
				<span class="text-capitalize mr-1">Назначить</span> исполнителя
			</v-btn>
			<DatePicker
				:todayButton="true"
				:allowPastDates="false"
				:nudgeTop="nudgeTop"
				:date.sync="dateCopy"
				:btnClass="'subtitle-1 font-weight-medium text-capitalize'"
				:disabled="disabled || (technicalSupervisor && !calledFromModal) || disableDate"
			/>
			<div class="paid d-flex align-center justify-space-between">
				<!-- <span v-if="disabled" class="subtitle-1 font-weight-light grey--text">
					<span v-if="paid">
						Платная: {{ price ? `${price} тг` : "Не указано" }}
					</span>
					<span v-else>Бесплатная</span>
				</span>
				<span
					v-else-if="!paid && status == 'new' || status == 'accepted' || status == 'in_work'"
					class="subtitle-1 font-weight-light grey--text"
				>
					Бесплатная
				</span> -->
				<v-checkbox
					v-if="!inner"
					class="text-simple"
					color="primary"
					:input-value="paid"
					:disabled="disabled || disablePaid"
					@change="updatePaid"
				>
				<template slot="default">
					<div class="text-simple ml-1">
						Платная
					</div>
				</template>
				<template slot="label">
					<div class="text-simple ml-1">
						Платная
					</div>
				</template>
			</v-checkbox>
			</div>
			<!-- <div v-else></div> -->
		</div>
		<v-chip-group
			class="d-flex align-center pl-3"
			column
			v-if="chips.length && !loading"
		>
			<v-chip
				v-for="(chip, idx) of chips"
				:key="idx"
				class="mr-5"
				color="grey lighten-4 dark--text"
				label
				close
				close-icon="mdi-close"
				:disabled="disabled || disableExecutors"
				@click:close="$emit('remove-chip', idx)"
			>
				{{ chip | fullname }}
			</v-chip>
		</v-chip-group>
		<span class="subtitle-2 grey--text font-weight-light pl-5" v-else>
			Нет выбранных исполнителей
		</span>
		<RequisitionsExecutorsModal
			v-if="showExecutorsModal"
			:alreadySelected="chips"
			:houseId="houseId"
			:kskId="kskId"
			:categoryId="categoryId"
			@close-modal="closeExecutorsModal"
		/>
		<!-- <pre>{{chips}}</pre> -->
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import DatePicker from "@/components/app/DatePicker";
import RequisitionsExecutorsModal from "@/components/RequisitionsExecutorsModal";

export default {
	name: "RequestExecutors",
	components: { DatePicker, RequisitionsExecutorsModal },
	props: {
		date: {
			required: true,
		},
		paid: {
			type: Boolean,
			default: false
		},
		price: {
			required: false,
		},
		categoryId: {
			type: String,
			default: "",
		},
		kskId: {
			type: String,
			default: "",
		},
		chips: {
			required: true,
			type: Array,
		},
		nudgeTop: {
			type: Number,
			default: 0,
		},
		status: {
			type: String,
			default: "new",
		},
		inner: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		disableDate: {
			type: Boolean,
			default: false,
		},
		disableExecutors: {
			type: Boolean,
			default: false,
		},
		disablePaid: {
			type: Boolean,
			default: false,
		},
		houseId: {
			type: String,
			default: "",
		},
		calledFromModal: {
			type: Boolean,
			default: false,
		},
		managerItr: {
			type: Boolean,
			default: false,
		},
		technicalSupervisor: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dateCopy: new Date().toISOString().substr(0, 10),
			loading: true,
			showExecutorsModal: false,
		};
	},
	computed: {
		...mapGetters({
			employee: "ksk/GET_EMPLOYEE",
		}),
	},
	watch: {
		dateCopy(newValue) {
			this.$emit("update:date", newValue);
		},
		houseId(newValue) {
			this.$emit("update:chips", []);
		},
	},
	methods: {
		closeExecutorsModal(selected) {
			this.showExecutorsModal = false;
			this.$emit("update:chips", selected);
		},
		updatePaid(newValue) {
			this.$emit("update:paid", Boolean(newValue));
		},
	},
	async created() {
		if (this.date === null) {
			console.log('date', this.dateCopy)
			this.dateCopy = ''
		} else {
			let finalDate = this.date
			if (typeof this.date === 'number') {
				const date = new Date(this.date * 1000);

				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');

				finalDate = `${year}-${month}-${day}`;
				console.log('finalDate', finalDate)
			}
			this.dateCopy = finalDate;
			this.$emit("update:date", this.dateCopy);
		}

		if (this.chips === null) this.$emit("update:chips", []);
		else if (this.chips.length && typeof this.chips[0] === "string") {
			const arr = await new Promise(resolve => {
				const arr = this.chips.map(e => this.employee.find(o => o._id === e));
				resolve(arr);
			});
			this.$emit("update:chips", arr);
		}
		this.loading = false;
	},
	mounted() {},
};
</script>

<style>
.text-simple .v-icon {
	color: #B5B9BD;
}
.icon-grey .v-icon {
	color: rgba(0, 0, 0, 0.54) !important;
}
</style>
