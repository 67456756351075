<template>
	<div class="d-flex relative">
		<v-chip-group
			class="border-all rounded-lg pa-1 mt-2"
			active-class="primary primary--text"
			:disabled="disabled"
			:show-arrows="false"
			:value="activeChip"
			:class="showDropdown ? 'dropdown' : ''"
			:column="showDropdown"
		>
			<v-chip
				outlined
				:disabled="disabled"
				v-for="(chip, index) of chipsCopy"
				:key="chip.name"
				@click="changeActiveChip(index)"
			>
				{{ chip.name }}
			</v-chip>
		</v-chip-group>
		<!-- Стрелка выпадающего списка -->
		<v-btn
			:class="showDropdown ? 'dropdown' : ''"
			class="absolute"
			icon
			@click="toggleDropdown"
		>
			<v-icon>mdi-chevron-down</v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: "ChipsGroup",
	props: {
		// INDEX
		activeChip: {
			required: true,
		},
		// CATEGORY_ID
		activeChipID: {
			type: String,
			required: true,
		},
		chips: {
			type: Array,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			applyChanging: -1,
			chipsCopy: [],
			showDropdown: false,
		};
	},
	methods: {
		// ? Меняет вид списка после выбора категории
		changeActiveChip(index) {
			if (index !== undefined && index !== null) {
				/**
				 * Если была выбрана какая-либо категория,
				 * то мы находим выбранный объект в исходном списке категорий
				 * и передаем родительскому компоненту его индекс
				 */
				const item = this.chipsCopy[index];
				const newIndex = this.chips.findIndex(e => e.id === item.id);
				this.updateActiveChip(newIndex, item.id);
			}

			/**
			 * Если категория была выбрана среди тех,
			 * которые находятся в выпадающей части
			 */
			if (this.showDropdown) {
				this.applyChanging = index;
				return;
			}

			/**
			 *  Если категория была выбрана из выпадающей части,
			 *  то меняем копию массива категорий, при этом не изменяя
			 *  исходный массив (prop) во избежание ошибок
			 *  @result Чип выбранной категории теперь находится на первом месте
			 *  а остальные категории находятся в исходной последовательности
			 */
			if (this.applyChanging !== -1) {
				const item = this.chipsCopy[this.applyChanging];
				const newIndex = this.chips.findIndex(e => e.id === item.id);
				this.chipsCopy = [...this.chips];
				this.chipsCopy.splice(newIndex, 1);
				this.chipsCopy.unshift(item);
				this.updateActiveChip(newIndex, item.id);
				this.applyChanging = -1;
			}
		},
		toggleDropdown() {
			this.showDropdown = !this.showDropdown;
			if (this.applyChanging !== -1) {
				this.changeActiveChip();
			}
		},
		updateActiveChip(e, id) {
			this.$emit("update:activeChip", e);
			this.$emit("update:activeChipID", id);
		},
	},
	mounted() {
		this.chipsCopy = [...this.chips];
	},
};
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__next,
::v-deep .v-slide-group__prev {
	display: none !important;
}

::v-deep .v-chip-group .v-slide-group__content {
	padding: 0 !important;
}

::v-deep .v-chip-group:not(.dropdown) .v-slide-group__content {
	flex: 0 auto !important;
	flex-wrap: wrap !important;
	max-height: 40px !important;
	white-space: wrap !important;
}
.absolute {
	position: absolute;
	z-index: 2;
	top: 17px;
	right: 10px;
}
.absolute.dropdown {
	transform: rotate(180deg);
}
.relative {
	position: relative;
}
</style>
