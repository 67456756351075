<template>
	<div class="requisitions-item-list d-flex flex-column">
		<div class="border d-flex justify-space-between align-center py-5 px-3">
			<v-btn class="pa-0" link depressed plain @click="$router.go(-1)">
				<v-icon color="grey darken-1" small>mdi-chevron-left</v-icon>
				<span class="caption">Список</span>
			</v-btn>
			<v-btn
				class="pa-0 grey--text text-lowercase"
				depressed
				plain
				@click="resetFilters"
			>
				<span class="caption">
					<span class="text-capitalize mr-1">
						Сбросить
					</span>
					фильтры
				</span>
			</v-btn>
		</div>

		<!-- Спиннер для загрузки новых записей  -->
		<div
			v-if="loadingMore || loading"
			class="d-flex justify-center"
			:class="{
				'h-100 border-right flex-sm-grow-1 overflow-y-auto': loading,
			}"
		>
			<v-progress-linear
				absolute
				indeterminate
				color="green"
			></v-progress-linear>
		</div>

		<!-- Записи -->
		<div
			class="list border-right d-flex flex-column align-center pa-3 flex-sm-grow-1 overflow-y-auto"
			@scroll="onScroll"
		>
			<RequisitionsItemListCard
				v-for="item of items"
				:key="item.id"
				:item="item"
				:id="item._id"
				@change-page="changePage(item)"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "@/components/app/DatePicker";
import RequisitionsItemListCard from "@/components/RequisitionsItemListCard";

export default {
	name: "RequisitionsItemList",
	components: {
		DatePicker,
		RequisitionsItemListCard,
	},
	data() {
		return {
			items: [],
			lastPage: null,
			loading: false,
			loadingMore: false,
			scrolledPages: [],
			page: +this.$route.query.page || 1,
		};
	},
	computed: {
		...mapGetters({
			companyIds: "auth/GET_COMPANY_IDS",
			kskId: "auth/GET_KSK_ID",
			base_role: "auth/GET_USER_ROLE",
			userHouses: "auth/GET_HOUSES_IDS",
			userCategories: "auth/GET_USER_CATEGORIES",	
		}),
		role() {
			return this.$store.getters["auth/GET_USER_ROLE_VUEX"];
		},
	},
	methods: {
		changePage(item) {
			const query = { ...this.$route.query, page: item.page, is_pause: item.is_pause };
			this.$router.replace({ query })
		},

		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			const firstPage = this.items[0].page;
			const lastPage = this.items[this.items.length - 1].page;

			if (
				scrollTop + clientHeight >= scrollHeight &&
				lastPage <= this.lastPage
			) {
				this.loadData();
			} else if (scrollTop === 0 && firstPage - 1 >= 1) {
				this.loadData(false);
			}
		},

		scrollIntoView(id) {
			document.getElementById(id).scrollIntoView({
				behavior: "smooth",
			});
		},

		routerRequisitions() {
			const path =
				this.role === "dispatcher"
					? "/dispatcher/requisitions"
					: "/requisitions";
			this.$router.push({ path });
		},

		resetFilters() {
			this.page = 1;
			this.scrolledPages = [];
			this.$router.replace({ query: { page: 1 } });
			this.loadData(undefined, true);
		},

		// https://stackoverflow.com/questions/59603315/how-to-detect-when-a-user-scrolls-to-the-bottom-of-a-div-vue

		async loadData(toDown = true, update = false) {
			try {
				let newPage;

				if (update) newPage = this.page;
				else if (toDown) newPage = this.items[this.items.length - 1].page + 1;
				else newPage = this.items[0].page - 1;

				if (this.scrolledPages.includes(newPage)) {
					return;
				}
				if (update) {
					this.loading = true;
				} else {
					this.loadingMore = true;
				}
				const params = { ...this.$route.query, page: newPage};
				console.log('123', params)
				if(this.base_role !== 'administrator' && this.base_role !== 'dispatcher') {
					params.company_ids = [this.kskId]
					params.selected_ksk_id = this.kskId
				}
				if(this.base_role == 'ksk_manager' || this.base_role == 'ksk_dispatcher') {
					if (!params || !params.houses_ids || params.houses_ids.length === 0) {
						const houseDataArray = this.userHouses.map(item => item.house);
						params.houses_ids = houseDataArray.map(item => item.id)
					}
					if(this.base_role == 'ksk_manager' && (!params.category_ids || params.category_ids.length === 0)) {
						params.category_ids = this.userCategories.map(item => item.id)
					}
				}
				if(params.employee_period_of_execution) {
					if(typeof params.employee_period_of_execution === 'string') {
						params.period_execution_first = params.employee_period_of_execution
						delete params.employee_period_of_execution
					} else {
						params.period_execution_first = params.employee_period_of_execution[0]
						params.period_execution_second = params.employee_period_of_execution[1]
						delete params.employee_period_of_execution
					}
				}
				if (typeof params.requests_types === 'string') {
					params.requests_types = [params.requests_types]
				}
				if (typeof params.houses_ids === 'string') {
					params.houses_ids = [params.houses_ids]
				}
				if (typeof params.category_ids === 'string') {
					params.category_ids = [params.category_ids]
				}
				if(params.created_by === 'inner') {
					params.is_inner = true
					params.created_by = 'company'
				} else if(params.created_by  === 'phone') {
					params.is_inner = false
					params.created_by = 'company'
				}
				if(params.request_type === '61989ebf061b482d199f4eda') {
					delete params.request_type
					params.is_overdue = true
				} else if(params.request_type === '5f4cda320796c90b114f5560' || params.request_type === '5f4cda330796c90b114f5561') {
					params.is_overdue = false
				}
				if(this.base_role === 'dispatcher') {
					params.dispatcher_mode = true
				}
				if (typeof params.employee_workers_ids === 'string') {
					params.employee_workers_ids = [params.employee_workers_ids]
				}
				const res = await this.$api.ksk.load_request(params);
				res.records = res.records.map(e => ({ ...e, page: newPage }));
				this.lastPage = res.pages;

				if (update) {
					this.items = [...res.records];
				} else if (toDown) {
					this.scrolledPages.push(newPage);
					this.items = [...this.items, ...res.records];
				} else {
					this.scrolledPages.unshift(newPage);
					this.items = [...res.records, ...this.items];
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = this.loadingMore = false;
			}
		},
	},
	async created() {
		await this.loadData(undefined, true);

		if (this.items.length < 10) {
			if (this.page - 1 >= 1) {
				this.page--;
				await this.loadData(false);
			}
		}
		// const { _id } = this.items.find(e => e._id === this.$route.params.id);
		// setTimeout(() => {
		// 	this.scrollIntoView(_id);
		// }, 100);
	},
};
</script>

<style lang="scss" scoped>
.requisitions-item-list {
	height: 100%;
	position: relative;
}
.h-100 {
	height: 100vh;
}
.list {
	flex: 1;
	max-height: 90vh;
}
</style>
