<template>
	<div class="ksk-table">
    <v-data-table
      :headers="headers"
      :items="items"
			item-key="_id"
			hide-default-footer
      :page.sync="options.page"
      :options="options"
      :server-items-length="options.itemsLength"
      :loading="loading"
      class="elevation-1"
    >
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
				<template v-slot:prepend>
					<div class="d-flex flex-sm-grow-1 align-center justify-start">
						<!-- Сброс фильтров -->
						<v-btn
							class="mr-5 text-lowercase"
							color="primary"
							small
							outlined
							@click="resetFilters"
						>
							<span class="body-2 font-weight-light">
								<span class="text-capitalize">Сбросить</span> фильтры
							</span>
						</v-btn>
							<!-- Сброс пароля -->
						<v-dialog
							v-model="dialog"
							width="500"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									class="mr-5 text-lowercase"
									color="primary"
									small
									outlined
									v-bind="attrs"
									v-on="on"
								>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> пароль
								</span>
							</v-btn>
							</template>

							<v-card>
								<v-card-title class="text-h5 grey lighten-2">
									Сбросить пароль
								</v-card-title>
								<v-card-text class="mt-3">
									<v-form ref="form" v-model="valid" class="mt-2" lazy-validation>
										<v-row>
											<v-col cols="6" md="5" xl="3" lg="4" class="mt-2">
												<CountryCode 
													@change-mask="changeMask($event)"
												/>
											</v-col>
											<v-col cols="6" md="7" xl="9" lg="8" class="d-flex align-center py-0 pl-0 pt-2">
												<v-text-field
													ref="phone"
													v-model="phone"
													v-mask="selected.mask"
													:placeholder="selected.code"
													persistent-placeholder
													name="phone"
													type="text"
													:disabled="loading"
													:label="$t('login.phoneNumber')"
													:rules="phoneRules"
													required
												></v-text-field>
											</v-col>
										</v-row>
									</v-form>
								</v-card-text>
								<v-divider></v-divider>

								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										color="primary"
										text
										:disabled="!valid"
										@click="resetPassword();"
									>
										Сбросить
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<RegisterModal @loadData="loadData" />
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn
									class="mr-5 text-lowercase"
									color="primary"
									small
									outlined
									@click="updateData"
									v-on="on"
								>
									<v-icon>mdi-refresh</v-icon>
								</v-btn>
							</template>
							<span>Обновить</span>
						</v-tooltip>
					</div>
				</template>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- ksk_confirm -->
					<td>
						<v-select
							v-model="filter.confirmed"
							class="border-all"
							solo
							dense
							flat
							hide-details
							:items="kskConfirmTypes"
							@change="updateQuery"
						>
						</v-select>
					</td>
					<!-- dispatcher_confirm -->
					<td>
						<v-select
							v-model="filter.confirmed_dispatcher"
							class="border-all"
							solo
							dense
							flat
							hide-details
							:items="kskConfirmTypes"
							@change="updateQuery"
						>
						</v-select>
					</td>
					<!-- Fullname ksk -->
					<td>
						<v-text-field
							v-model="filter.name"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td></td>
					<!-- Phone -->
					<td>
						<v-text-field
							v-model="filter.phone"
							class="border-all"
							placeholder="Пример: +7-707-"
							v-mask="'+#-###-###-##-##'"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
				</tr>
			</template>
			<template v-slot:[`item.username`]="{ item }">
				<td>
					<span v-if="item.user">
						<span v-show="item.user.name">{{item.user.name}} </span>
						<span v-show="item.user.surname">{{ item.user.surname }} </span>
						<span v-show="item.user.patronymic">{{ item.user.patronymic }}</span>
					</span>
				</td>
			</template>
			<template v-slot:[`item.confirmed`]="{ item }">
				<td>
					<v-btn
						v-if="!item.confirmed"
						class="subtitle-2 text-capitalize"
						color="primary"
						small
						depressed
						:loading="isConfirming === item._id"
						@click.stop="toggleKskRegistration(item)"
					>
						Подтвердить
					</v-btn>
					<v-tooltip v-else bottom>
						<template v-slot:activator="{ on, attrs }">
							<span
								class="subtitle-2 font-weight-light"
								v-bind="attrs"
								v-on="on"
								@click.stop="toggleKskRegistration(item)"
							>
								Подтвержден
							</span>
						</template>
						<span>Нажмите для отмены</span>
					</v-tooltip>
				</td>
			</template>
			<template v-slot:[`item.confirmed_dispatcher`]="{ item }">
				<td style="display: flex; justify-content: center;">
					<v-checkbox
						
						v-model="item.confirmed_dispatcher"
						@click.stop="toggleKskDispatcherConfirm(item)"
					>
					</v-checkbox>
				</td>
			</template>
			<template v-slot:[`item.analytic`]="{ item }">
				<v-btn
					class="mr-5 text-lowercase"
					color="warning"
					small
					@click="presentAnalytic(item)"
				>
					<span class="body-2 font-weight-light">
						<span class="text-capitalize">BI Аналитика</span>
					</span>
				</v-btn>
			</template>
			<template v-slot:[`item.login`]="{ item }">
				<td v-if="item.confirmed">
						<v-btn
							class="mr-5 text-lowercase"
							color="primary"
							small
							@click="presentAlert(item)"
						>
						<span class="body-2 font-weight-light">
							<span class="text-capitalize">Войти в УК</span>
						</span>
					</v-btn>
					<!-- <pre>{{ item.name }}</pre>  -->
				</td>
			</template>
		</v-data-table>
		<v-dialog
			v-model="dialogAnalytic"
			width="500"
		>
			<v-card>
				<v-card-title class="text-h5">
					BI Аналитика
				</v-card-title>
				<v-card-text>
					<p v-if="itemKsk.bi_analytic_link">{{itemKsk.bi_analytic_link}}</p>
					<p v-else>Нет данных</p>
					<v-text-field
						v-model="bi_analytic_link"
						type="text"
						:disabled="loading"
						label="Введите значение"
						flat
						dense
						outlined
						required
						hide-details
					></v-text-field>
				</v-card-text>
				<div class="d-flex justify-center">
					<v-progress-circular
						v-show="loadingData"
						:size="50"
						color="primary"
						indeterminate
					></v-progress-circular>
				</div>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						text
						@click="dialogAnalytic = false"
					>
						Отмена
					</v-btn>
					<v-btn
						color="green darken-1"
						text
						@click="saveAnalytic(itemKsk)"
					>
						Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogKsk"
			width="500"
		>
			<v-card>
				<v-card-title class="text-h5">
					Вход в УК
				</v-card-title>
				<v-card-text>
					Войти в аккаунт Управляющей компании - {{ itemKsk.name }}
				</v-card-text>
				<div class="d-flex justify-center">
					<v-progress-circular
						v-show="loadingData"
						:size="50"
						color="primary"
						indeterminate
					></v-progress-circular>
				</div>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						text
						@click="dialogKsk = false"
					>
						Отмена
					</v-btn>
					<v-btn
						color="green darken-1"
						text
						@click="loginKsk(itemKsk)"
					>
						Войти
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import { timestampToDdmmyyyy } from "@/helpers/helpers";
import CountryCode from "@/components/app/SelectCountryCode.vue";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import TenantsAddModal from "@/components/TenantsAddModal";
import RegisterModal from "@/components/RegisterModal";

export default {
	name: "TenantsTable",
	components: {
		DatePickerPlain,
		TenantsAddModal,
		RegisterModal,
		CountryCode
	},
	data() {
		return {
			itemKsk: {
				name: '',
				id: '',
				phone: '',
				bi_analytic_link: ''
			},
			selected: {
				code: '',
				mask: ''
			},
      ksk: null,
			loadingData: false,
			valid: false,
			firstFetch: false,
			dialog: false,
			dialogKsk: false,
			dialogAnalytic: false,
			bi_analytic_link: "",
			phone: "",
			phoneRules: [
				v => !!v || this.$t("login.errors.enterPhone"),
				v =>
					this.selected.code === '+7' ? ((v && v.length == 16) || this.$t("login.errors.incorrectPhoneFormat")) : (v && v.length > 14) || this.$t("login.errors.incorrectPhoneFormat"),
			],
			items: [],
			filter: {
				confirmed: this.$route.query.confirmed || "",
				confirmed_dispatcher: this.$route.query.confirmed_dispatcher || "",
				name: this.$route.query.name || "",
				phone: this.$route.query.phone || "",
			},
			filtersQuery: {
				"confirmed": "confirmed",
				"confirmed_dispatcher": "confirmed_dispatcher",
				"name": "name",
				"phone": "phone",
			},
			headers: [
				{
					text: "Подтверждение УК",
					value: "confirmed",
					width: "40px",
					sortable: false,
				},
				{
					text: "Подтверждение диспетчерам",
					value: "confirmed_dispatcher",
					width: "40px",
					sortable: false,
				},
				{
					text: "Наименование УК",
					value: "name",
					width: "250px",
					sortable: false,
				},
				{
					text: "ФИО директора",
					value: "username",
					width: "250px",
					sortable: false,
				},
				{
					text: "Телефон",
					value: "user.phone",
					width: "200px",
					sortable: false,
				},
				{
					text: "Аналитика",
					value: "analytic",
					width: "200px",
					sortable: false,
				},
				{
					text: "Вход",
					value: "login",
					width: "200px",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			kskConfirmTypes: [
				{ text: "Все", value: null },
				{ text: "Не подтвержденные", value: "false" },
				{ text: "Подтвержденные", value: "true" },
			],
			isConfirming: false,
			isExportingExcel: false,
			loading: true,
			showModalCreate: false,
		};
	},

	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					console.log('firstFetch', this.firstFetch)
					const query = { ...this.$route.query, page: this.options.page };
					console.log('query', query)
					this.$router.replace({ query });
					this.loadData(query);
				}
			},
			deep: true,
		},
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			role: "auth/GET_USER_ROLE_VUEX",
			base_role: "auth/GET_USER_ROLE",
      token: "auth/GET_TOKEN",
		}),

	},

	methods: {
		updateData() {
			let params = { paginate: true, page: 1, paginate: true, forget_cache: true }
			this.loadData(params);
		},
		changeMask(event) {
			this.$nextTick(() => {
				this.phone = '';
			});
			this.selected = event
		},
		presentAnalytic(item) {
			this.itemKsk.id = item._id
			this.itemKsk.bi_analytic_link = item.bi_analytic_link
			this.dialogAnalytic = true
		},
		presentAlert(item) {
			this.itemKsk.name = item.name
			this.itemKsk.id = item._id
			this.itemKsk.phone = item.user.phone
			this.dialogKsk = true
		},
		async saveAnalytic(item) {
			this.loadingData = true
			let params = {
				bi_analytic_link: this.bi_analytic_link,
				_method: 'patch'
			}
			try {
				const res = await this.$api.administrator.save_ksk_analytic(params, item.id);
				console.log('res', res)
				this.dialogAnalytic = false
				this.bi_analytic_link = ''
				const query = { ...this.$route.query };
				this.loadData(query)
			} catch (error) {
				console.log('err', error)
			}	finally {
				this.loadingData = false;
			}
		},
		async loginKsk(item) {
			this.loadingData = true
			try {
				const res = await this.$api.administrator.getTokenKsk(item.id);
				console.log('RES TOKEN', res);

				this.$store.dispatch("auth/saveAdminToken", this.token)
				
				const resStore = await this.$store.dispatch("auth/loginKsk", res);
				await this.$store.dispatch("ksk/load_ksk_info");
				this.$store.dispatch("ksk/load_houses");
				console.log('res dispatch auth/loginKsk', resStore)
				const countryCode = item.phone.split("-")[0];
				console.log('countryCode', countryCode)
				this.$store.dispatch("auth/userSavePhoneCode", countryCode)
				this.$router.push("/");
			} catch (error) {
				console.error(error);
				this.$root.snackbar.show({ color: "red", message: error });
			} finally {
				this.loadingData = false;
			}

		},
    async resetPassword() {
			try {
				this.loading = true;
				const res = await this.$api.administrator.reset_password({
					phone: this.phone, test: true
				});
				console.log('res2', res)
				this.dialog = false
				this.$root.snackbar.show({ color: "success", message: 'Пароль успешно сброшен' });
			} catch (error) {
				console.error(error);
				this.$root.snackbar.show({ color: "error", message: error.message });
			} finally {
				this.loading = false;
			}
    },
    async loadData(params) {
			try {
				params.paginate = true
				this.loading = true;	
				const res = await this.$api.administrator.load_ksk({
					...params
				});
				this.options.page = res.currentPage;
				this.options.pageCount = res.pages;
				this.options.itemsLength = res.pages * 10;
				this.items = res.records;
        console.log('items posts', this.items)
				console.log('RES', res);
				setTimeout(() => {
					this.firstFetch = true
				}, 500);
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
    },
		// * Подтверждение УК
		async toggleKskRegistration(item) {
			try {
				this.isConfirming = item.id;
				await this.$api.administrator.confirm_ksk(
					item.id, !item.confirmed
				);
				item.confirmed = !item.confirmed;
			} catch (error) {
				console.error(error);
			} finally {
				this.isConfirming = false;
			}
		},
		// * Подтверждение Диспетчерам
		async toggleKskDispatcherConfirm(item) {
			try {
				await this.$api.administrator.confirm_ksk_dispatcher(
					item.id, item.confirmed_dispatcher
				);
			} catch (error) {
				console.error(error);
			} 
		},

		// ? Начало методов таблица
		// handleRowClick(e) {
		// 	// TODO: сделать поиск пользователя по _id
		// 	if (window.getSelection().toString()) {
		// 		return;
		// 	}
		// 	this.$router.push(`/tenants/${e._id}`);
		// },	


		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.$router.replace({ query: null });
			await this.loadData({page: 1});
		},

		updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	// beforeCreate() {
	// 	this.$store.dispatch("catalog/loadKskList");
	// },
	async mounted() {
		try {
			const query = { ...this.$route.query, page: this.options.page };
			console.log('23123', query)
			this.$router.replace({ query });
			await this.loadData(query);
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.notifications-table {
	height: 100vh;
}
.disable-events {
  pointer-events: none
}
</style>
