<template>
<div>
	<nav class="header">
		<div class="header__menu">
			<div class="header__logo">
				<img class="logo-img" src="@/assets/logo.svg" alt="logo" />
				<img class="logo-text" src="@/assets/logo-text.svg" alt="logo" />
			</div>
			{{ apiUrl === 'https://devapi.aula.city' ? 'test api' : '' }}
			<ul class="header__nav">
				<v-tabs
					class="d-flex flex-grow-1"
					align-with-title
					slider-size="3"
					show-arrows
					center-active
				>
					<v-tabs-slider color="primary"></v-tabs-slider>

					<v-tab
						:id="'head-menu-'+link.id"
						v-for="link in links"
						:key="link.id"
						:to="link.to"
						class="subtitle-1 font-weight-light text-capitalize"
					>
						<v-badge
							v-if="link.text == 'Жители' && count"
							color="red darken-1"
							:content="count"
						>
						{{ link.text }}
						</v-badge>
						<span v-else>{{ link.text }}</span>
					</v-tab>
				</v-tabs>
			</ul>
		</div>
		<span v-if="base_role !== 'dispatcher' && base_role !== 'administrator'" class="pr-5">
			<span class="text-caption">Вы работаете в:</span>
			<v-select
				v-model="ksk_id"
				class="border-all"
				solo
				dense
				flat
				hide-details
				item-text="name"
				item-value="id"
				:items="userData.ksk"
				@change="updateCurrentKskId"
				style="max-width: 200px;"
			>
			</v-select>
		</span>
		<div class="mr-3">
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-icon
						:color="tokenDevice ? 'green' : 'red'"
						v-on="on"
					>
						{{ tokenDevice ? 'mdi-check-circle' : 'mdi-alert-circle' }}
					</v-icon>
				</template>
				<span v-if="tokenDevice">Уведомления включены</span>
				<span v-else>Для стабильной работы сайта необходимо включить уведомления</span>
			</v-tooltip>
		</div>
		<TheHeaderProfile />
	</nav>
	<v-alert 
		v-if="adminToken"
		dense
		color="blue"
		type="info"
		text
		class="ma-0"
	>
		<v-btn :ripple="false" color="info" small text @click="authAdmin">Перейти в аккаунт администратора</v-btn>
		
	</v-alert>
	<v-dialog v-model="dialog" persistent width="350">
		<v-card>
			<v-card-title class="text-body-1 border-bottom">
				Выберите УК
			</v-card-title>
			<v-card-text class="border-bottom py-5">
				<slot name="body">
					<v-select
						v-model="ksk_id"
						class="border-all"
						placeholder="Управляющая компания"
						solo
						dense
						flat
						hide-details
						item-text="name"
						item-value="id"
						:items="userData.ksk"
					>
					</v-select>
				</slot>
			</v-card-text>
			<v-card-actions class="d-flex justify-center">
				<v-btn color="primary" depressed @click="updateCurrentKskId">
					Ок
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</div>
</template>

<script>
import { mapGetters } from "vuex";

import TheHeaderProfile from "@/components/TheHeaderProfile";

export default {
	name: "TheHeader",
	components: { TheHeaderProfile },
	data() {
		return {
			dialog: false,
			count: null,
			ksk_id: null,
			apiUrl: process.env.VUE_APP_API_URL,
			// * By default links are of KSK and KSK_MANAGER roles
			links: [],
			adminLinks: [
				{ id: 1, text: "Заявки", to: "/requisitions" },
				{ id: 2, text: "Аналитика", to: "/bi-analytics" },
				{ id: 3, text: "QR", to: "/qr" },
				{ id: 4, text: "Жители", to: "/tenants" },
				{ id: 5, text: "Сотрудники", to: "/employee" },
				{ id: 6, text: "Дома", to: "/houses" },
				{ id: 7, text: "Анкета", to: "/faq" },
				// { id: 7, text: "Посты", to: "/posts" },
				{ id: 8, text: "УК", to: "/ksk" },
			],
			dispatcherLinks: [
				{ id: 1, text: "Главная", to: "/dispatcher/home" },
				{ id: 2, text: "Заявки", to: "/dispatcher/requisitions" },
				{ id: 3, text: "Уведомления", to: "/dispatcher/notifications" },
			],
			dispatcherKskLinks: [
				{ id: 1, text: "Главная", to: "/dispatcher/home" },
				{ id: 2, text: "Заявки", to: "/dispatcher/requisitions" },
			],
			kskLinks: [
				{ id: 1, text: "Заявки", to: "/requisitions" },
				{ id: 2, text: "Уведомления", to: "/notifications" },
				{ id: 3, text: "Аналитика", to: "/bi-analytics" },
				{ id: 4, text: "Жители", to: "/tenants" },
				{ id: 5, text: "Сотрудники", to: "/employee" },
				{ id: 6, text: "Дома", to: "/houses" },
				{ id: 7, text: "QR", to: "/qr" },
				{ id: 8, text: "QR New", to: "/qr-next" },
				{ id: 9, text: "Голосование", to: "/vote" },
				{ id: 10, text: "Анкета", to: "/questionnaire" },
				
				// { id: 10, text: "Прайслист", to: "/pricelist" },
			],
			supervisorLinks: [
				{ id: 1, text: "Предписания", to: "/requisitions" },
				{ id: 2, text: "Жители", to: "/tenants" },
				{ id: 3, text: "Сотрудники", to: "/employee" },
				{ id: 4, text: "Дома", to: "/houses" },
			],
		};
	},

	computed: {
		...mapGetters({
			kskId: "auth/GET_KSK_ID",
			new_qr_exist: "ksk/GET_NEWQR_EXIST",
			role: "auth/GET_USER_ROLE_VUEX",
			base_role: "auth/GET_USER_ROLE",
			adminToken: "auth/GET_ADMIN_TOKEN",
			base_technical_supervisor: "auth/GET_TECHNICAL_SUPERVISOR",
			tokenDevice: "notifications/GET_DEVICE_TOKEN",
			userData: "auth/GET_INDIVIDUAL_DATA",
			houses: "ksk/GET_HOUSES",
		}),
	},

	watch: {
		role() {
			this.setLinks();
		},
		houses() {
			if(this.adminToken) {
				this.loadCount()
			}
		},
		new_qr_exist() {
			this.setLinks();
		},
		'kskId'() {
			console.log('KSK ID CHANGE', this.kskId)
			if(this.kskId) {
				if(this.base_role !== 'dispatcher' && this.base_role !== 'administrator' && this.userData.ksk.length > 1 && !this.kskId) {
					this.dialog = true
				}
				this.ksk_id = this.kskId
			}
		},
		'userData'() {
			setTimeout(() => {
				this.ksk_id = this.kskId
			}, 100);
		}
	},

	methods: {
		updateCurrentKskId() {
			this.$store.dispatch("auth/setKskId", this.ksk_id);
			this.$store.dispatch("auth/load_data")
			setTimeout(() => {
				window.location.reload()
			}, 700);
		},
		async authAdmin() {
			const res = await this.$store.dispatch("auth/changeAuthToken", this.adminToken)
			if(res) {
				this.count = null
				this.$store.dispatch("auth/userSavePhoneCode", '+7')
				this.$router.push("/ksk");
			}
		},
		async loadCount() {
			if(this.houses.length < 70 && this.houses.length > 0) {
				if(this.base_role !== 'administrator' && this.base_role !== 'dispatcher') {
					const housesIds = this.houses.map(house => house.id);
					const params = {
						not_approwed_users: true,
						houses_ids: housesIds
					}
					const response = await this.$api.ksk.load_unconfirmed_tenants(params)
					this.count = response.not_approwed_users
				}
			}
		},
		setLinks() {
			if (!this.new_qr_exist.length) {
				this.kskLinks = this.kskLinks.filter(link => link.id !== 8);
			} else {
				const hasId8 = this.kskLinks.some(link => link.id === 8);

				if (!hasId8) {
					this.kskLinks = [
						{ id: 1, text: "Заявки", to: "/requisitions" },
						{ id: 2, text: "Уведомления", to: "/notifications" },
						{ id: 3, text: "Аналитика", to: "/bi-analytics" },
						{ id: 4, text: "Жители", to: "/tenants" },
						{ id: 5, text: "Сотрудники", to: "/employee" },
						{ id: 6, text: "Дома", to: "/houses" },
						{ id: 7, text: "QR", to: "/qr" },
						{ id: 8, text: "QR New", to: "/qr-next" },
						{ id: 9, text: "Голосование", to: "/vote" },
						{ id: 10, text: "Анкета", to: "/questionnaire" },
					]
				}
			}
			this.links = [...this.kskLinks];
			// * If any other role, change it
			if(this.base_technical_supervisor) {
				this.links = [...this.supervisorLinks];
				return;
			}
			if(this.base_role === 'dispatcher') {
				this.links = [...this.dispatcherLinks];
				return;
			}
			if (this.role === "administrator") {
				this.links = [...this.adminLinks];
			} else if (this.role === "dispatcher") {
				this.links = [...this.dispatcherKskLinks];
			}
		},
	},
	created() {
		this.setLinks();
		this.loadCount()
		if(this.base_role !== 'dispatcher' && this.base_role !== 'administrator' && this.userData.ksk.length > 1 && !this.kskId) {
			this.dialog = true
		}
		this.ksk_id = this.kskId
	},
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	padding: 0px 20px;
	/* max-width: 1440px; */
	width: 100%;
	background-color: #fefefe;
}
.header__menu {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: stretch;
	padding: 0px;
	width: 1202px;
}
.header__nav {
	display: flex;
	align-items: stretch;
	margin-left: 30px;
	padding: 0;
	max-width: 750px;

	@media screen and (max-width: 1960px) {
		max-width: 950px;
	}
	@media screen and (max-width: 1560px) {
		max-width: 750px;
	}
	@media screen and (max-width: 1260px) {
		max-width: 680px;
	}
	@media screen and (max-width: 1182px) {
		max-width: 600px;
	}
	@media screen and (max-width: 1110px) {
		max-width: 300px;
	}
}
.link {
	display: flex;
	align-items: center;
	color: #363636;
	font-weight: 300;
	font-size: 16px;
	padding: 0 24px;
	cursor: pointer;
	transition: all 0.3s ease;
}
.link:hover,
.link.router-link-active,
.link.router-link-exact-active {
	box-shadow: inset 0px -4px 0px #2eb85c;
}
.header__logo {
	display: flex;
	align-items: center;
}
.logo-img {
	margin-right: 22.12px;
}
.burger {
	display: none;
	align-items: center;
}

::v-deep .v-tabs-bar {
	height: 100%;
}
/* @media screen and (max-width: 1200px) {
	.header__nav,
	.header__user {
		display: none;
	}
	.burger {
		display: flex;
	}
} */
</style>
