<template>
	<v-dialog
		v-model="dialog"
		width="900"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				class="mr-5"
				color="primary"
				small
				outlined
				v-bind="attrs"
				v-on="on"
			>
				<span class="body-2 font-weight-light">
					<span class="text-capitalize">Создать</span> УК
				</span>
			</v-btn>
		</template>
		<v-card class="elevation-12">
			<!-- Хэдер -->
			<v-toolbar dark flat color="primary">
				<v-toolbar-title>
					Регистрация
				</v-toolbar-title>
				<v-spacer />
				<v-btn color="dark" @click="dialog = false" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<!-- Поиск по БИН -->
			<!-- <v-card-text v-if="!showManualForm" class="px-6 pb-0">
				<v-alert
					v-if="alert"
					dense
					text
					:type="alert.type || 'warning'"
				>
					{{ alert.message }}
				</v-alert>

				<v-form class="mt-2" lazy-validation>
					<v-text-field
						v-model="bin"
						v-mask="'############'"
						label="БИН"
						placeholder="000000000000"
						counter
						persistent-placeholder
						name="bin"
						append-icon="mdi-magnify"
						type="text"
						:rules="rules.bin"
						:disabled="loading"
						:loading="loading"
						@click:append="findKskByBin"
						required
					></v-text-field>
				</v-form>
			</v-card-text> -->

			<!-- Ручной ввод -->
			<v-card-text class="px-6 pb-0">
				<v-alert
					v-if="alert"
					dense
					text
					:type="alert.type || 'warning'"
					class="mt-2"
				>
					{{ alert.message }}
				</v-alert>

				<v-form ref="form" class="mt-2" lazy-validation>
					<v-row>
						<!-- БИН -->
						<v-col cols="12" sm="6">
							<span class="subtitle-2 grey--text font-weight-light mb-2"
								>БИН</span
							>
							<v-text-field
								v-model="data.bin"
								v-mask="'############'"
								class="border-all"
								hide-details
								flat
								solo
								dense
								type="text"
								:rules="rules.bin"
								required
							></v-text-field>
						</v-col>
						<!-- Наименование -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Наименование
							</span>
							<v-text-field
								v-model="data.name"
								class="border-all"
								hide-details
								flat
								solo
								dense
								type="text"
								:rules="rules.required"
								required
							></v-text-field>
						</v-col>
						<!-- Имя -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Имя
							</span>
							<v-text-field
								v-model="data.director_name"
								class="border-all"
								hide-details
								flat
								solo
								dense
								type="text"
								:rules="rules.required"
								required
							></v-text-field>
						</v-col>
						<!-- Фамилия -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Фамилия
							</span>
							<v-text-field
								v-model="data.director_surname"
								class="border-all"
								hide-details
								flat
								solo
								dense
								type="text"
								:rules="rules.required"
								required
							></v-text-field>
						</v-col>
						<!-- Отчество -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Отчество
							</span>
							<v-text-field
								v-model="data.director_patronymic"
								class="border-all"
								hide-details
								flat
								solo
								dense
								type="text"
							></v-text-field>
						</v-col>
						<!-- Город -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Город
							</span>
							<v-select
								v-model="data.city_id"
								class="border-all"
								hide-details
								flat
								solo
								dense
								:items="catalog.cities"
								item-text="name"
								item-value="id"
								:rules="rules.required"
								required
							></v-select>
						</v-col>
						<!-- Адрес -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Адрес
							</span>
							<v-text-field
								v-model="data.address_street"
								class="border-all"
								hide-details
								flat
								solo
								dense
								:rules="rules.required"
								required
							></v-text-field>
						</v-col>
						<!-- Тип -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Тип
							</span>
							<v-select
								v-model="data.organization_type"
								class="border-all"
								hide-details
								flat
								solo
								dense
								:items="organization_types"
								item-text="name"
								item-value="_id"
								:rules="rules.required"
								required
							></v-select>
						</v-col>
						<!-- Телефон -->
						<v-col cols="12" sm="6">
							<span class="subtitle-2 grey--text mb-2  font-weight-light">
								Телефон
							</span>
							<v-row>
								<v-col cols="6" md="5" xl="4" lg="4" class="mt-2">
									<CountryCode 
										@change-mask="changeMask($event)"
									/>
								</v-col>
								<v-col cols="6" md="7" xl="8" lg="8" class="d-flex align-center py-0 pl-0 pt-2">
									<v-text-field
										v-model="data.phone"
										v-mask="selected.mask"
										:placeholder="selected.code"
										class="border-all"
										hide-details
										flat
										solo
										dense
										:rules="rules.required"
										required
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<!-- Email -->
						<v-col cols="12" sm="6">
							<span
								class="subtitle-2 grey--text mb-2  font-weight-light"
							>
								Email
							</span>
							<v-text-field
								v-model="data.email"
								class="border-all"
								hide-details
								flat
								solo
								dense
							></v-text-field>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<!-- Футер -->
			<v-card-actions class="pa-6 pt-4 d-flex flex-start">
				<v-btn
					v-if="showManualBtn"
					class="mr-4 subtitle-2 text-capitalize"
					color="primary"
					depressed
				>
					Ввести вручную
				</v-btn>
				<v-btn
					class="mr-4 subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="loading"
					@click="registerKsk"
				>
					Создать аккаунт
				</v-btn>
				<!-- <div class="d-flex flex-column">
					<router-link
						class="href subtitle-2 font-weight-regular"
						to="/login"
					>
						Страница входа
					</router-link>
				</div> -->
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import CountryCode from "@/components/app/SelectCountryCode.vue";
export default {
	name: "Register",
	data() {
		return {
			alert: null,
      dialog: false,
			bin: "",
			selected: {
				code: '',
				mask: ''
			},
			data: {
				bin: "",
				name: "",
				email: "",
				director_name: "",
				director_surname: "",
				director_patronymic: "",
				address_street: "",
				city_id: "",
				organization_type: "",
				phone: "",
				director_phone: "",
			},
			loading: false,
			timer: null,
			showManualBtn: false,
			showManualForm: false,
			rules: {
				bin: [v => (!!v && v.length === 12) || "БИН состоит из 12 цифр"],
				required: [v => !!v || ""],
			},
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			organization_types: "ksk/GET_ORGANIZATION_TYPES",
		}),
	},
	watch: {
		bin(newVal) {
			clearTimeout(this.timer);
			this.timer = null;
			if (newVal.length === 12) {
				this.timer = setTimeout(() => this.findKskByBin(), 500);
			}
		},
	},
	methods: {
		changeMask(event) {
			this.$nextTick(() => {
				this.data.phone = '';
			});
			this.selected = event
		},
		async findKskByBin() {
			this.alert = null;
			this.showManualBtn = false;

			try {
				this.loading = true;
				const res = await this.$api.ksk.load_ksk_info_bin(this.bin);
				this.data = { ...this.data, ...res };
				// this.toggleManualForm();
			} catch (error) {
				this.showManualBtn = true;
				this.alert = {
					type: "error",
					message: error.message || "Не удалось найти БИН в базе данных",
				};
			} finally {
				this.loading = false;
			}
		},

		async registerKsk() {
			this.alert = null;

			try {
				this.loading = true;

				if (!this.$refs.form.validate()) return;
				const data = {
					name: this.data.director_name,
					surname: this.data.director_surname,
					patronymic: this.data.director_patronymic,
					phone: this.data.phone,
					email: this.data.email,
					role_id: '5f4cda2f0796c90b114f5535',
					registration: 'self'
				}
				const user = await this.$api.ksk.add_employee(data);
				console.log('user', user)
				this.data.user_id = user.id
				this.data.director_phone = this.data.phone
				const res = await this.$api.ksk.register_ksk(this.data);
				const dataEdit = {
					ksk_ids: [],
					employee_position_ids: [],
					_method: 'patch'
				}
				dataEdit.ksk_ids.push(res.id)
				dataEdit.employee_position_ids.push({ksk_id: res.id, employee_position_id: '60f50807032c86715a0baf2f'})
				await this.$api.ksk.save_employee(dataEdit, user.id, res.id);
				// this.alert = {
				// 	type: "success",
				// 	message: res.success,
				// };
        this.$root.snackbar.show({ color: "success", message: res.success || "Успешно" });
				// setTimeout(() => this.$router.push("/login"), 500);

				this.$refs.form.reset();
        this.dialog = false
        this.$emit('loadData')
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.loading = false;
			}
		},
		// toggleManualForm() {
		// 	this.showManualBtn = false;
		// 	this.alert = null;
		// 	this.showManualForm = true;
		// 	this.data.bin = this.bin;
		// },
		resetValidation() {
			this.$refs.form.resetValidation();
		},
	},
	created() {
		// this.$store.dispatch("ksk/load_organization_types");
	},
	components: {
		CountryCode
	}
};
</script>

<style lang="scss" scoped>
.register {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f5f5f5;
}

::v-deep .v-text-field.v-text-field--solo.v-input--dense {
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
	&.error--text {
		position: relative;
		border-color: red !important;
		&:focus-within {
			box-shadow: 0px 0px 4px rgb(255, 97, 97);
		}
		.v-text-field__details {
			display: block !important;
			position: absolute;
			bottom: -25px;
			left: -10px;
		}
		.v-input__slot {
			margin-bottom: 0px !important;
		}
	}
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 33px !important;
	font-size: 14px !important;
}
::v-deep .v-text-field__details {
	display: none !important;
}
</style>
