<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">
					<span v-if="!dataToEdit">Добавление нового сотрудника</span>
					<span v-else>Редактирование сотрудника</span>
				</span>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-form ref="form" lazy-validation>
						<!-- Персональные данные -->
						<v-row>
							<v-col cols="12">
								<span class="subtitle-1 black--text px-0">
									Персональные данные
								</span>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0 required">Номер телефона</label>
								<v-text-field
									v-model="data.phone"
									:placeholder="phoneCode+'-'"
									v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									:disabled="dataToEdit ? true : false"
									:rules="rules.phone"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0 required">Фамилия</label>
								<v-text-field
									v-model.trim="data.surname"
									placeholder="Введите фамилию"
									class="border-all"
									solo
									dense
									flat
									required
									hide-details="auto"
									:rules="rules.required"
									:disabled="disableInputs"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0 required">Имя</label>
								<v-text-field
									placeholder="Введите имя"
									v-model.trim="data.name"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									:rules="rules.required"
									:disabled="disableInputs"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Отчество</label>
								<v-text-field
									placeholder="Введите отчество"
									v-model.trim="data.patronymic"
									class="border-all"
									solo
									dense
									flat
									hide-details
									:disabled="disableInputs"
								>
								</v-text-field>
							</v-col>
							<!-- <v-col md="4" cols="12">
								<label class="caption-2 mt-0">ИИН</label>
								<v-text-field
									placeholder="Введите ИИН"
									v-model="data.iin"
									v-mask="'############'"
									class="border-all"
									solo
									dense
									flat
									hide-details="auto"
								>
								</v-text-field>
							</v-col> -->
						</v-row>
						<!-- Должностная информация -->
						<v-row v-if="base_role !== 'administrator'">
							<v-col cols="12">
								<span class="subtitle-1 black--text px-0">
									Должностная информация
								</span>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0 required">Должность</label>
								<v-select
									v-model="data.employee_position_id"
									placeholder="Выберите"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									item-text="name"
									item-value="id"
									:items="catalog.employee_positions"
									:rules="rules.required"
								>
								</v-select>
							</v-col>
							<v-col md="4" cols="6">
								<label class="caption-2 mt-0 required">Роль</label>
								<v-select
									placeholder="Выберите"
									v-model="data.role_id"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									item-text="name"
									item-value="id"
									:items="base_role == 'ksk_manager' ? RolesForManager : cRoles"
									:rules="rules.required"
									:disabled="dataToEdit ? (dataToEdit.ksk ? dataToEdit.ksk.length > 1 : false) || disableRoleId : false || disableRoleId"
								>
								</v-select>
							</v-col>
							<v-col md="4" cols="6" v-if="base_role === 'ksk' && base_technical_supervisor && data.role_id == '5f4cda2f0796c90b114f5536'">
								<v-checkbox
									v-model="technical_supervisor"
									class="ft-14 mt-6"
									label="Менеджер ИТР"
									hide-details
								>

								</v-checkbox>
							</v-col>
							<v-col cols="12">
								<label class="caption-2 mt-0 required">
									Обслуживаемые адреса
								</label>
								<Autocomplete
									v-model="data.ksk_houses_ids"
									class="border-all pt-0"
									hide-details
									flat
									solo
									clearable
									deletable-chips
									multiple
									required
									select-all="true"
									placeholder="Выберите адреса"
									item-text="apartment_address"
									item-value="id"
									:filter="addressFilter"
									:items="houses"
									:dense="!Boolean(data.ksk_houses_ids.length)"
									:small-chips="Boolean(data.ksk_houses_ids.length)"
								>
								</Autocomplete>
							</v-col>
							<!-- Доступные категории -->
							<v-col cols="12" v-if="data.role_id !== '5f4cda2f0796c90b114f5537' && data.role_id !== '61fba08f69d79635b83c1d27'">
								<label class="caption-2 mt-0 required">
									Доступные категории
								</label>
								<Autocomplete
									v-if="!base_technical_supervisor"
									v-model="data.ksk_category_ids"
									clearable
									deletable-chips
									multiple
									required
									placeholder="Выберите категории"
									item-text="name"
									item-value="id"
									select-all="true"
									:items="cRequestCategories"
									:dense="!Boolean(data.ksk_category_ids.length)"
									:small-chips="Boolean(data.ksk_category_ids.length)"
								></Autocomplete>
								<Autocomplete
									v-else
									v-model="data.ksk_category_ids"
									clearable
									deletable-chips
									multiple
									required
									placeholder="Выберите категории"
									item-text="name"
									item-value="id"
									select-all="true"
									:items="catalog.request_categories_technical_supervision"
									:dense="!Boolean(data.ksk_category_ids.length)"
									:small-chips="Boolean(data.ksk_category_ids.length)"
								></Autocomplete>
							</v-col>
						</v-row>
						<!-- Alert -->
						<v-row v-if="alert">
							<v-col cols="12">
								<v-alert dense text :type="alert.color">
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isSaving"
					@click="saveEmployee"
					:disabled="disableBtn"
				>
					Сохранить
				</v-btn>
			</v-card-actions>
		</v-card>

		<!-- Модальное окно -->
		<ModalWarning
			v-if="showModalWarning"
			:title="'Добавление нового сотрудника'"
			:text="'Вы успешно добавили нового сотрудника'"
			@close-modal="closeModal"
		/>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import ModalWarning from "@/components/app/ModalWarning";

import addressFilterMixin from "@/mixins/addressFilterMixin";

export default {
	name: "EmployeeTableModal",
	mixins: [addressFilterMixin],
	components: {
		ModalWarning,
	},
	props: {
		dataToEdit: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			alert: null,
			disableBtn: false,
			disableInputs: false,
			disableRoleId: false,
			isEditable: false,
			userId: null,
			selectAllHouses: null,
			technical_supervisor: false,
			roles_add: null,
			data: {
				ksk_houses_ids: [],
				ksk_category_ids: [],
				employee_position_id: null,
				employee_position_ids: [],
				name: null,
				patronymic: null,
				phone: null,
				role_id: null,
				surname: null,
				registration: 'by_manager'
				// all_houses: false,
				// email: null,
				// iin: null,
				// houses: [],
				// is_manager: null,
				// roles: null,
			},
			editData: {
				ksk_houses_ids: [],
				ksk_category_ids: [],
				employee_position_ids: [],
				ksk_ids: [],
				_method: 'patch'
			},
			isUserRole: false,
			editableKsk: [],
			requestId: '',
			originalData: {},
			changedData: {},
			date: new Date().toISOString().substr(0, 10),
			dialog: true,
			edited: false,
			loading: true,
			showModalWarning: false,
			isSaving: false,
			rules: {
				required: [v => !!v || ""],
				phone: [
					v => !!v || "",
					v => this.phoneCode === '+7' ? ((v && v.length === 16) || this.$t("login.errors.incorrectPhoneFormat")) : (v && v.length > 14) || this.$t("login.errors.incorrectPhoneFormat"),
				],
				houses: [v => !v.length],
			},
		};
	},
	watch: {
		'data.phone'() {
			if(!this.dataToEdit) {
				this.alert = null
				this.disableBtn = false
				this.disableInputs = false
				this.disableRoleId = false
				this.isEditable = false
				this.data.name = ''
				this.data.surname = ''
				this.data.patronymic = ''
				if(this.data.phone.length === 16) {
					this.getUserByPhone()
				}
			}
		},
		'selectAllHouses' () {
			if(this.selectAllHouses) {
				this.houses.forEach(el => {
					this.data.ksk_houses_ids.push(el.id)
				});
			} else {
				this.data.ksk_houses_ids = []
			}
		}
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			kskId: "auth/GET_KSK_ID",
			base_role: "auth/GET_USER_ROLE",
			base_technical_supervisor	: "auth/GET_TECHNICAL_SUPERVISOR",
			base_techinal_engineers: "auth/GET_TECHINAL_ENGINEERS",
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
		cRequestCategories() {
			return this.catalog.request_categories;
		},
		cRoles() {
			const roles = ["Менеджер кск", "Работник кск", "Диспетчер ук"];
			return this.catalog.roles
				.filter(e => roles.includes(e.name))
				.map(e => {
					const name = e.name.includes("Работник кск")
						? "Сотрудник"
						: e.name.split(" ")[0];
					return { ...e, name };
				});
		},
		RolesForManager() {
			const roles = ["Работник кск", "Диспетчер ук"];
			return this.catalog.roles
				.filter(e => roles.includes(e.name))
				.map(e => {
					const name = e.name.includes("Работник кск")
						? "Сотрудник"
						: e.name.split(" ")[0];
					return { ...e, name };
				});
		}
	},
	methods: {
		getUserByPhone() {
			try {
				const params = {
					paginate: true,
					page_count: 10,
					phone: this.data.phone,
					page: 1
				}
				this.$api.ksk
				.load_employee(params).then(res => {
					// this.options.page = res.page;
					if(res.records.length) {
						if(res.records[0].role.name === 'Кск') {
							this.disableBtn = true
							this.disableInputs = true
							this.disableRoleId = true
							this.data.name = res.records[0].name
							this.data.surname = res.records[0].surname
							this.data.patronymic = res.records[0].patronymic
							this.alert = {
								color: "error",
								message: "Данный пользователь уже является председателем в другой КСК",
							};
						} else if(res.records[0].role.name === 'Пользователь') {
							console.log('ksk_houses', res.records[0].ksk_houses)
							this.isUserRole = true
							this.requestId = res.records[0].id
							this.data.ksk_houses_ids = res.records[0].ksk_houses
							this.data.ksk_category_ids = res.records[0].ksk_category
							this.data.employee_position_id = res.records[0].employee_positions[0]?.employee._id || null;
							this.data.name = res.records[0].name
							this.data.patronymic = res.records[0].patronymic
							this.data.phone = res.records[0].phone
							this.data.surname = res.records[0].surname
							this.editableKsk = res.records[0].ksk ? res.records[0].ksk : []
							this.isEditable = true
						} else {
							if(res.records[0].ksk !== null) {
								const foundKsk = res.records[0].ksk.find(e => e.id === this.kskId)
								console.log('foundKsk', foundKsk)
								
								if(foundKsk) {
									if(res.records[0].ksk.length === 1) {
										console.log('res.records.length 1', res.records[0].ksk.length)
										this.disableRoleId = false
									} else {
										console.log('res.records.length 2', res.records[0].ksk.length)
										this.disableRoleId = true
										this.data.role_id = res.records[0].role.id
									}
									this.disableBtn = true
									this.disableInputs = true
									this.data.name = res.records[0].name
									this.data.surname = res.records[0].surname
									this.data.patronymic = res.records[0].patronymic
									this.alert = {
										color: "error",
										message: "Пользователь с таким номером уже зарегистрирован в вашей компании",
									};
								} else {
									this.disableInputs = true
									this.disableRoleId = true
									this.data.role_id = res.records[0].role.id
									this.data.name = res.records[0].name
									this.data.surname = res.records[0].surname
									this.data.patronymic = res.records[0].patronymic
									res.records[0].ksk.forEach(el => {
										this.editData.ksk_ids.push(el.id)
									});
								}
								
								return this.userId = res.records[0].id
							} else {
								this.requestId = res.records[0].id
								this.data.ksk_houses_ids = res.records[0].ksk_houses
								this.data.ksk_category_ids = res.records[0].ksk_category
								this.data.employee_position_id = res.records[0].employee_positions[0]?.employee._id || null;
								this.data.name = res.records[0].name
								this.data.patronymic = res.records[0].patronymic
								this.data.phone = res.records[0].phone
								this.data.surname = res.records[0].surname
								this.data.role_id = res.records[0].role.id
								this.editableKsk = res.records[0].ksk ? res.records[0].ksk : []
								this.isEditable = true
								this.disableInputs = true
							}
						}
					}
					this.userId = null
					this.editData.ksk_ids = []
				})
			} catch (error) {
				console.log('err', error)
			}
		},
		closeModal(isConfirmed = true) {
			this.dialog = false;
			this.$emit("close-modal", isConfirmed);
		},
		async editExistsUser() {
			try {
				this.isSaving = true;
				this.editData.ksk_ids.push(this.kskId)
				this.editData.employee_position_ids.push({ksk_id: this.kskId, employee_position_id: this.data.employee_position_id})
				this.data.ksk_houses_ids.forEach(element => {
					this.editData.ksk_houses_ids.push({ksk_id: this.kskId, house_id: element})
				});
				this.data.ksk_category_ids.forEach(element => {
					this.editData.ksk_category_ids.push({ksk_id: this.kskId, category_id: element})
				});
				await this.$api.ksk.save_employee(this.editData, this.userId, this.kskId);
				this.alert = {
					color: "success",
					message: "Изменения сохранены",
				};
				setTimeout(() => this.$emit("close-modal"), 500);
				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Попробуйте еще раз",
				};
			} finally {
				this.isSaving = false;
			}
		},
		async saveEmployee() {
			this.alert = null;

			if (!this.$refs.form.validate()) {
				return;
			}
			// Choose at least one address
			if (!this.data.ksk_houses_ids.length && this.base_role !== 'administrator') {
				this.alert = {
					color: "error",
					message: "Выберите как минимум 1 обслуживаемый адрес",
				};
				return;
			}
			if(this.userId) {
				return this.editExistsUser()
			}
			try {
				this.isSaving = true;

				if (this.dataToEdit || this.isEditable) {
					// this.changedData = {};
    			// this.compareData(this.data, this.originalData);
					if(this.base_role === 'administrator') {
						this.editData = {
							_method: "patch",
							name: this.data.name,
							surname: this.data.surname,
							patronymic: this.data.patronymic
						}
					} else {
						this.editData.name = this.data.name
						this.editData.surname = this.data.surname
						this.editData.patronymic = this.data.patronymic
						const houses_ids = this.data.ksk_houses_ids
						console.log('dataToEdit', this.dataToEdit)
						this.editData.ksk_houses_ids = []
						houses_ids.forEach(element => {
							this.editData.ksk_houses_ids.push({ksk_id: this.kskId, house_id: element})
						});
						let foundKsk
						if(!this.isEditable) {
							foundKsk = this.dataToEdit.ksk.find(e => e.id === this.kskId)
						} else {
							foundKsk = this.editableKsk.find(e => e.id === this.kskId)
						}
						
						if(foundKsk) {
							delete this.editData.ksk_ids
						} else {
							this.editData.ksk_ids.push(this.kskId)
						}
						if(this.isUserRole || (this.dataToEdit.ksk.length === 1 && (this.base_role === 'ksk' || this.base_role === 'ksk_manager'))) {
							this.editData.role_id = this.data.role_id
						}
						const ksk_category_ids = this.data.ksk_category_ids
						this.editData.ksk_category_ids = []
						console.log('ksk_category_ids', ksk_category_ids)
						ksk_category_ids.forEach(element => {
							this.editData.ksk_category_ids.push({ksk_id: this.kskId, category_id: element})
						});
						this.editData.employee_position_ids.push({ksk_id: this.kskId, employee_position_id: this.data.employee_position_id})
					}
					const requestId = this.dataToEdit ? this.dataToEdit.id : this.requestId
					await this.$api.ksk.save_employee(this.editData, requestId, this.kskId);
					this.alert = {
						color: "success",
						message: "Изменения сохранены",
					};
					setTimeout(() => this.$emit("close-modal"), 500);
				} else {
					const houses_ids = this.data.ksk_houses_ids
					this.data.ksk_houses_ids = []
					houses_ids.forEach(element => {
						this.data.ksk_houses_ids.push({ksk_id: this.kskId, house_id: element})
					});
					const ksk_category_ids = this.data.ksk_category_ids
					this.data.ksk_category_ids = []
					console.log('ksk_category_ids', ksk_category_ids)
					ksk_category_ids.forEach(element => {
						this.data.ksk_category_ids.push({ksk_id: this.kskId, category_id: element})
					});
					this.data.employee_position_ids.push({ksk_id: this.kskId, employee_position_id: this.data.employee_position_id})
					delete this.data.employee_position_id;
					this.data.ksk_ids = [this.kskId]
					if(this.technical_supervisor) this.data.techinal_engineers = true;
					await this.$api.ksk.add_employee(this.data);
					this.showModalWarning = true;
				}
				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Попробуйте еще раз",
				};
			} finally {
				this.isSaving = false;
			}
		},
		compareData(obj1, obj2) {
			for (const key in obj1) {
				if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
					if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
						if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
							this.changedData[key] = obj1[key];
						}
					} else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
						this.compareData(obj1[key], obj2[key]);
					} else if (obj1[key] !== obj2[key]) {
						this.changedData[key] = obj1[key];
					}
				}
			}
		},
		
		arraysAreEqual(arr1, arr2) {
			if (arr1.length !== arr2.length) {
				return false;
			}
			for (let i = 0; i < arr1.length; i++) {
				if (arr1[i] !== arr2[i]) {
					return false;
				}
			}
			return true;
		},
	},

	async created() {
		if (this.dataToEdit) {
			const houses = this.dataToEdit.ksk_houses.map(item => item.house.id);
			console.log('houses', houses)
			this.dataToEdit.ksk_houses_ids = houses
			if(this.dataToEdit.ksk_category.length) {
				const categories = this.dataToEdit.ksk_category.map(item => item.category.id);
				this.dataToEdit.ksk_category_ids = categories
			} else {
				this.dataToEdit.ksk_category_ids = []
			}
			if(this.dataToEdit.role.name_system === 'ksk_employee' || this.dataToEdit.role.name_system === 'ksk_manager' || this.dataToEdit.role.name_system === 'ksk_dispatcher') {
				if(this.dataToEdit.ksk.length > 1 && this.base_role !== 'administrator') {
					this.disableInputs = true
				}
			}
			// this.data = this.dataToEdit.deepCopy();
			this.data.ksk_houses_ids = this.dataToEdit.ksk_houses_ids
			this.data.ksk_category_ids = this.dataToEdit.ksk_category_ids
			this.dataToEdit.employee_positions.forEach(el => {
				if(el.ksk.id === this.kskId) {
					this.data.employee_position_id = el.employee.id
				}
			});
			this.data.name = this.dataToEdit.name
			this.data.patronymic = this.dataToEdit.patronymic
			this.data.phone = this.dataToEdit.phone
			this.data.surname = this.dataToEdit.surname
			this.data.role_id = this.dataToEdit.role.id
			console.log('data', this.data)
			// this.data.houses = []
			// this.dataToEdit.ksk_houses.forEach(el => {
			// 	this.data.houses.push(el._id)
			// });
			this.technical_supervisor = this.data.techinal_engineers
			
			delete this.data._id;
			this.originalData = this.data.deepCopy();
		}

	},
	async mounted() {
	},
};
</script>

<style lang="scss" scoped>
::v-deep .col {
	padding-bottom: 0 !important;
}
.required::after {
	content: "*";
	position: absolute;
	color: red;
	margin-left: 3px;
}
</style>
